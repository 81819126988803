<template>
	<div>
		<!-- 公共头部 -->
		<Header></Header>
				
		<ul class="list">
			<li class="item">
				<div class="box1">订单号：{{orderInfo.order}}</div>
				<div class="box2">
					<div class="pic">
						<img class="icon" :src="orderInfo.img" />
					</div>
					<div class="content">
						<div class="title">{{orderInfo.subject}}</div>
						<div class="info">
							<p>{{orderInfo.school}}</p>
							<p>下单时间：{{orderInfo.addtime}}</p>
						</div>
					</div>
				</div>
				<div class="box3">
					<span class="text">单价：¥{{orderInfo.money}}</span>
					<span class="text">实付款：¥{{orderInfo.sfmoney}}</span>
				</div>
				<div class="box4">退款金额：<font color="#ff2e2e">¥{{orderInfo.sfmoney}}</font></div>
			</li>
		</ul>
		
		<div class="section1">
			<div class="cate-title">退款原因</div>
			<textarea class="textarea" placeholder="请填写您的退款原因" v-model="recase"></textarea>
		</div>
		
		<div class="submit-box">
			<div class="submit" @click="submit">申请退款</div>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '申请退款',
				orderInfo: '',
				recase: ''
			}
		},
		computed: {
			uid() {
				return this.$store.state.uid;
			}
		},
		activated() {
			this.getUserInfo();
		},
		deactivated() {
			this.orderInfo = '';
		},
		methods: {
			async submit() {
				var id = this.orderInfo.id;
				var recase = this.recase;
				var uid = this.uid;
				var data = {
					id, recase, uid
				}
				if(!data['recase']){
					this.$messageBox.alert('请输入退款原因', '温馨提示');
					return false;
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.post('/order/refundOrder',data);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('提交退款申请：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.$messageBox.alert(res.data, '温馨提示', {
						confirmButtonText: '确定',
						callback: action => {
							this.$router.go(-1);
						}
					});
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			// 获取订单详情
			async getOrderInfo() {
				var uid = this.uid;
				var order = this.$route.query.order;
				var data = {
					uid, order
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/order/order', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('订单详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var orderInfo = res.data;
					if(orderInfo['img'] && orderInfo['img'].substring(0,4) != 'http'){
						orderInfo['img']=this.$store.state.http + orderInfo['img'];
					}
					orderInfo['addtime'] = this.utils.js_date_time(orderInfo['addtime'],2);
					this.orderInfo = orderInfo;
				} else {
					this.orderInfo = '';
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return;
					}
						wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getOrderInfo();
				}
			},
		},
	};
</script>

<style scoped>
	.list{
		padding: 0.3rem 0;
	}
	.list .item{
		margin-top: 0.4rem;
		padding: 0 0.3rem 0.3rem;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.list .item:first-child{
		margin-top: 0;
	}
	.list .item .box1{
		height: 0.86rem;
		display: flex;
		align-items: center;
		font-size: 0.26rem;
		color: #333333;
	}
	.list .item .box2{
		display: flex;
		align-items: flex-start;
	}
	.list .item .box2 .pic{
		width: 1.6rem;
		height: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.list .item .box2 .pic .icon{
		max-height: 100%;
	}
	.list .item .box2 .content{
		margin-left: 0.3rem;
	}
	.list .item .box2 .content .title{
		font-size: 0.32rem;
		color: #333333;
		margin-bottom: 0.12rem;
	}
	.list .item .box2 .content .info{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.44rem;
	}
	.list .item .box3{
		margin-top: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.list .item .box3 .text{
		font-size: 0.26rem;
		color: #888888;
	}
	.list .item .box3 .text:last-child{
		color: #333333;
		margin-left: 0.3rem;
	}
	.list .item .box4{
		margin-top: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.section1{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		margin-top: 0.3rem;
	}
	.section1 .cate-title{
		height: 0.8rem;
		display: flex;
		align-items: center;
		padding: 0 0.3rem;
		border-bottom: 0.02rem solid #eeeeee;
		position: relative;
	}
	.section1 .cate-title::before{
		content: '*';
		display: inline-block;
		position: absolute;
		font-size: 0.26rem;
		color: #ff6000;
		left: 0.12rem;
		top: 50%;
		transform: translate(0,-50%);
		-ms-transform: translate(0,-50%);
		-o-transform: translate(0,-50%);
		-moz-transform: translate(0,-50%);
		-webkit-transform: translate(0,-50%);
	}
	.section1 .textarea{
		display: block;
		width: 100%;
		height: 2.4rem;
		padding: 0.24rem 0.3rem;
		font-size: 0.26rem;
		border: none;
		resize: none;
		
-webkit-user-select:auto; /*webkit浏览器*/  
    user-select:auto;
    -o-user-select:auto;
    -ms-user-select:auto;
	}
	.submit-box{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 1rem;
	}
	.submit-box .submit{
		width: 5.8rem;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #121e5f;
		font-size: 0.3rem;
		color: #ffffff;
		border-radius: 0.5rem;
	}
</style>